import React from "react"
import { Link } from "gatsby"
import "../../../styles/service.scss"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

const WeddingPackages = ({ data }) => (
  <Layout>
    <Header />
    <SEO
      title="We Help You Plan A Wedding To Remember | Wedding Packages"
      description="YES Inspired offers a variety of wedding planning services in Toronto and the greater Toronto area to every bride and groom, both local and destination. From stationary to flowers, from cupcakes to centerpieces, we're here to provide you with the wedding details that set your day apart from everyone else's."
    />
    <div className="services-page-container">
      <Fade>
        <div className="services-page-heading-wrapper">
          <h2 className="services-page-sub-heading">Explore our</h2>
          <h1 className="services-page-heading">Wedding Services</h1>
          <p class="services-page-body">
            YESInspired Events is a full-service event planning and design
            company helping couples plan their dream weddings. We offer wedding
            planning and design services for both the DIY bride as well as the
            couple that relies on an expert to do it all. Over the years, we
            have worked closely with some of the best venues, wedding planners,
            florists, and entertainment professionals to ensure your wedding day
            goes off without a hitch. From full wedding planning to detailed
            décor to linen selections, YESInspired Events can assist you in
            creating your perfect day.
          </p>
        </div>
      </Fade>
      <div className="wedding-inclusion-wrapper">
        <div className="wedding-inclusion-section">
          <Img
            className="wedding-inclusion-image"
            fluid={data.prePlan.childImageSharp.fluid}
          />
          <div className="wedding-inclusion-text-wrapper">
            <Fade>
              <h3 className="wedding-inclusion-heading">
                PRE-PLANNING INCLUSIONS
              </h3>
              <div className="service-component-line">&nbsp;</div>
              <p className="wedding-inclusion-subtitle">
                Including but not limited to:
              </p>
              <ul className="wedding-inclusion-list">
                <li className="wedding-inclusion-item">
                  Custom design concept & mood board
                </li>
                <li className="wedding-inclusion-item">
                  Exclusive access to your own shared google drive folder
                </li>
                <li className="wedding-inclusion-item">
                  Attendance of vendor meetings and facilitation of vendor
                  bookings
                </li>
                <li className="wedding-inclusion-item">
                  Site visit to venue and constant direct communication with
                  chosen venue
                </li>
                <li className="wedding-inclusion-item">
                  Ceremony & reception coordination & logistics planning
                </li>
                <li className="wedding-inclusion-item">
                  Set up detailed schedule for your rehearsal, wedding and
                  reception
                </li>
                <li className="wedding-inclusion-item">
                  Regular scheduled meetings throughout the planning process
                  (beginning with once a week, meetings condense later in the
                  planning process)
                </li>
                <li className="wedding-inclusion-item">
                  Creation of wedding day documents to distribute to vendors,
                  staff and wedding party
                </li>
                <li className="wedding-inclusion-item">
                  Wedding planning spreadsheet with month to month to do list to
                  assist in planning
                </li>
              </ul>
            </Fade>
          </div>
        </div>
        <div className="wedding-inclusion-section">
          <Img fluid={data.monthOf.childImageSharp.fluid} />
          <div className="wedding-inclusion-text-wrapper">
            <Fade>
              <h3 className="wedding-inclusion-heading">DAY OF INCLUSIONS</h3>
              <div className="service-component-line ">&nbsp;</div>
              <p className="wedding-inclusion-subtitle">
                Including but not limited to:
              </p>
              <ul className="wedding-inclusion-list">
                <li className="wedding-inclusion-item">
                  Lead planner and main liaison with Caterer/Venue, DJ, MC,
                  Photographer and all vendors
                </li>
                <li className="wedding-inclusion-item">
                  1-2 assistants throughout the day, at the ceremony and
                  reception and during setup
                </li>
                <li className="wedding-inclusion-item">
                  Hands-on support throughout the final coordination and
                  execution of your day
                </li>
                <li className="wedding-inclusion-item">
                  Communication with all vendors on site ( Catering team, venue
                  manager, florists, musicians etc )
                </li>
                <li className="wedding-inclusion-item">
                  Coordination of agenda throughout the day of the wedding (
                  Morning - Evening )
                </li>
                <li className="wedding-inclusion-item">
                  Organizing the ceremony - lining up of wedding party and
                  communication with officiant
                </li>
                <li className="wedding-inclusion-item">
                  Assistance throughout the day to ensure the program runs
                  smoothly
                </li>
                <li className="wedding-inclusion-item">
                  Styling of ceremony and reception details: decor,
                  centrepieces, welcome table, favours, menu cards, table
                  numbers, escort cards, etc.
                </li>
              </ul>
            </Fade>
          </div>
        </div>
      </div>
      <div className="service-component-list">
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image"
            fluid={data.fullPlanning.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper">
            <Fade>
              <h3 className="wedding-component-heading">
                Full Service Wedding Package
              </h3>
              <p className="service-component-body">
                YESInspired Events is here to help you enjoy your wedding day!
                We begin anywhere from 10-12 months before your wedding date and
                help you find the perfect venue for your special day. This
                package includes, but is definitely not limited to: A décor
                scheme with a matching mood/storyboard, the hiring and
                negotiation of all your vendors any of your vendors including:
                venue coordinator, florist, cake designer, dj or band, etc.,
                professional lighting for the ceremony site as well as for
                reception space. <br></br>
                <br></br>Set up your free consultation with us and we will
                customize a proposal specifically tailored for you!
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button ">
                  Set up my consultation!
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image flip-1"
            fluid={data.weddingMonth.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper flip-2">
            <Fade>
              <h3 className="wedding-component-heading">
                Partial Wedding Package
              </h3>
              <p className="service-component-body">
                ​Partial Wedding Planning allows you to take the leading role as
                the main Wedding Planner, while we aid with the final details
                and logistics of your wedding. Together, we tailor a custom
                planning package that best suits your needs. With this package,
                you also have the option to upgrade your package to partial
                planning & wedding design where we help you design the wedding
                as a whole alongside your Vendors as well as our amazing Lead
                Designer to ensure that your vision truly comes to life.
                <br></br>
                <br></br>
                Send us a message when you’re ready and we will be happy to set
                up your complimentary call and help you power through the rest
                of your wedding planning journey!
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button ">
                  Set up my consultation!
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image"
            fluid={data.fullDesign.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper">
            <Fade>
              <h3 className="wedding-component-heading">
                Month/Day Of Wedding Package
              </h3>
              <p className="service-component-body">
                This allows you to take the leading role in wedding planning
                from the beginning stages to the final 2 months. The Yes team
                will handle the rest of the coordination and vendor management
                on the month of as well as creating your wedding timeline,
                agenda and organizing your wedding rehearsal if needed.
                <br></br>
                <br></br>
                Let us help you celebrate your wedding day stress free! Send us
                a message below!
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button ">
                  Set up my consultation!
                </button>
              </Link>
            </Fade>
          </div>
        </div>
        {/*SERVICE COMPONENT*/}
        <div className="service-component">
          <Img
            className="service-component-image flip-1"
            fluid={data.weddingPartial.childImageSharp.fluid}
          />
          <div className="service-component-text-wrapper flip-2">
            <Fade>
              <h3 className="wedding-component-heading">
                Full Service + Design<br></br> Wedding Package
              </h3>
              <p className="service-component-body">
                This package will take a huge weight off your shoulders as we
                guide and advise you through the entire planning process. It is
                perfect for the busy couple or for those planning from afar. We
                help you find your dream venue, create a stunning décor scheme,
                then handle the hiring, negotiation and management of all your
                vendors. From budget management and tracking payments to a
                personalized planning schedule, our Full Service Planning
                package has all the help you need to make wedding planning
                manageable and enjoyable. It also includes all the perks of our
                Wedding Management package.
                <br></br>
                <br></br>
                Send us your details below to schedule your complimentary
                consultation call to go over the details of this luxurious
                wedding package!
              </p>
              <Link to="/contact" className="service-component-link">
                <button className="service-component-button ">
                  Set up my consultation!
                </button>
              </Link>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default WeddingPackages

export const weddingQuerry = graphql`
  query {
    prePlan: file(relativePath: { eq: "pre-planning.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monthOf: file(relativePath: { eq: "month-day-of.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fullPlanning: file(relativePath: { eq: "service-page-weddings.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    weddingPartial: file(relativePath: { eq: "wedding-partial.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    weddingMonth: file(relativePath: { eq: "wedding-month.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fullDesign: file(relativePath: { eq: "full-design.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
